import request from '@/utils/request'

// 验证链接是否过期
export function checkExpr(query) {
    return request({
        url: '/h5/face-record/checkExpr',
        method: 'get',
        params: query
    })
}
// 获取活体授权信息
export function getGetDetectInfo(data) {
    return request({
        url: '/h5/face/getGetDetectInfo',
        method: 'post',
        data: data
    })
}
// 获取活体授权信息
export function applyFaceUrl(data) {
    return request({
        url: 'h5/face/applyFaceUrl',
        method: 'post',
        data: data
    })
}


export function faceCallBack(data) {
    return request({
        url: '/h5/face/faceCallBack',
        method: 'post',
        data: data
    })
}
