<template>
  <div class="container">

  </div>
</template>

<script>
// @ is an alias to /src
import GoodsItem from "@/components/GoodsItem/index.vue"

import { listComment } from "@/api/test.js"
export default {
  name: 'Home',
  components: {
    GoodsItem
  },
  data() {
    return {
      value1: 0,
      value2: 'a',
      total: 0,
      loading: false,
      finished: false,
      refreshing: false,

      queryParams: {
        name: "",
        pageSize: 10,
        pageNum: 1,
      },
      option1: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 },
      ],
      option2: [
        { text: '默认排序', value: 'a' },
        { text: '好评排序', value: 'b' },
        { text: '销量排序', value: 'c' },
      ],
      policyList: [],// 渲染页面列表
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 触底加载更多
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.policyList = [];
          this.refreshing = false;
        }
        // 执行list请求方法...
        if(this.finished===false){
          this.getList();
        }

      }, 1000);
    },
    // 下拉刷新
    onRefresh() {
      // 清空列表数据
      this.onResetQuery();
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    // 点击搜索
    onSearch(val) {
      this.onResetQuery();
      this.getList()
    },
    // 重置
    onResetQuery() {
      this.total = 0;
      this.finished = false;
      this.policyList = [];
      this.queryParams.pageNum = 1;
    },
    // 获取列表
    getList() {
      // listComment().then(res => {
      // 只负责累加
      this.policyList = [...this.policyList, ...[{
        id: 1,
        title: "PullRefresh 的内容未填满屏幕时，只有一部分区域可以下拉？",
        name: "A局",
        createTime: "2023年8月14日"
      }, {
        id: 2,
        title: "探索Vue 3世界中的12个流行组件库",
        name: "B局",
        createTime: "2023年8月15日"
      }, {
        id: 3,
        title: "作为一个功能强大的Material Design风格组件库，Vuetify 3为Vue 3提供了大量精美的UI组件和样式，帮助你轻松构建现代化的Web应用程序。",
        name: "C局",
        createTime: "2023年8月16日"
      },]];
      this.total = 20;
      this.loading = false;
      this.queryParams.pageNum += 1;
      if (this.policyList.length >= this.total) {
          this.finished = true;
        }
      // })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  background-color: #F7F7F7;

  .header {
    // padding: 0 25px;
    background-color: #fff;
  }

  .content {
    margin-top: 20px;
    padding: 0 50px;
    background-color: #fff;
  }
}
</style>
