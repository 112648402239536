<template>
  <div>
    <img src="@/assets/temp.jpg" />
  </div>
</template>

<script>
export default {
  data(){
    return {
      jump:false,
    }
  },
  created() {
    if (this.jump){
      window.location = "https://action.fenqile.com/page/VlVdTUhcV1RFSVZQWURA/index.html?launch_id=1317967"
    }
  },
}
</script>
<style scoped>
img {
  max-width: 100%; /* 图片最大宽度为100% */
  height: auto; /* 高度自动调整以保持宽高比 */
}
</style>
