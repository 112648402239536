<template>
    <div class="container-goods-item" @click="onItem">
        <p>{{ item.title }}</p>
        <div class="info">
            <van-tag color="#E6F2FE" text-color="#1A75C8" size="medium">{{ item.name }}</van-tag>
            <span>{{ item.createTime }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "vinit",
    props: {
        item: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {

        }
    },
    computed: {},
    watch: {},
    filters: {},
    created() {

    },
    methods: {
        onItem() {
            console.log(this.item)
        },
    },
}
</script>

<style scoped lang="scss">
.container-goods-item {
    padding: 30px 0;
    border-bottom: 1px solid #D7D7D7;

    >p {
        /* 1.溢出隐藏 */
        overflow: hidden;
        /* 2.用省略号来代替超出文本 */
        text-overflow: ellipsis;
        /* 3.设置盒子属性为-webkit-box  必须的 */
        display: -webkit-box;
        /* 4.-webkit-line-clamp 设置为2，表示超出2行的部分显示省略号，如果设置为3，那么就是超出3行部分显示省略号 */
        -webkit-line-clamp: 2;
        /* 5.字面意思：单词破坏：破坏英文单词的整体性，在英文单词还没有在一行完全展示时就换行  即一个单词可能会被分成两行展示 */
        word-break: break-all;
        /* 6.盒子实现多行显示的必要条件，文字是垂直展示，即文字是多行展示的情况下使用 */
        -webkit-box-orient: vertical;
        /* 7.最重要的一步,设置宽度*/
        width: 100%;
    }

    .info {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        >span {
            color: #999999;
        }
    }
}
</style>