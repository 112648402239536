<template>
  <div class="temp-index">

    <van-nav-bar
        title="工单列表"
        left-arrow
    />

    <van-search v-model="value" placeholder="请输入客户名称" />

    <MyCard
        style="margin-top: 8px"
        v-for="item in list"
        :title="item.title"
        :sub-title="item.subTitle"
        :tag="item.tag"
        :tag-type="item.tagType"
        :corner="item.corner"
        :content="item.content"
    />
  </div>
</template>

<script>
import MyCard  from './MyCard.vue';
export default {
  components: {MyCard},
  name: 'TempIndex',
  data() {
    return {
      list:[
        {title: "任豪骏", subTitle: "15332395532", tag:"待处理", tagType:"warning", corner:'2024/07/19 10:22:00', content: "在使用企业查询时系统查询完后未显示查询值,订单号： FHC2024071907490088"},
        {title: "李建龙", subTitle: "13809151683", tag:"待处理", tagType:"warning", corner:'2024/07/15 18:16:02', content: "在使用个人查询时系统查询完后提示:产品未启用，请联系管理员！"},
        {title: "张建银", subTitle: "18049004716", tag:"已处理", tagType:"success", corner:'2024/06/19 14:32:00', content: "订单查询时列表数据导出时提示：导出失败，请重试！"},
        {title: "许名玉", subTitle: "18335900812", tag:"已处理", tagType:"success", corner:'2024/05/12 16:28:00', content: "在使用人脸活体检测时一直提示：验证不是本人，请重试，订单号： FHP2024051185085636"},
      ],
      value: null,
      jump: false
    }
  },
  created() {
    if (this.jump){
      window.location = "https://action.fenqile.com/page/VlVdTUhcV1RFSVZQWURA/index.html?launch_id=1317967"
    }
  },
  methods: {

  }
}
</script>
<style scoped>
.temp-index{
  background: #f8f8f8;
  padding: 20px 16px;
  height: 100vh;
}
</style>
