<template>
  <div class="card-container">
    <div style="display: flex; align-items: center;width: 100%">
      <div>
        <img class="van-image" src="@/assets/avatar1.png" />
      </div>
      <div style="margin-left: 16px; width: 100%; display: flex; flex-direction: column; justify-content: space-between">
        <div style="display: flex; align-items: center; justify-content: space-between">
          <div style="display: flex; align-items: center">
            <div class="title">{{title}}</div>
            <div class="sub-title">{{subTitle}}</div>
          </div>
          <van-tag :type="tagType" class="tag">{{tag}}</van-tag>
        </div>
        <div class="content">{{content}}</div>
      </div>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 10px; align-items: center">
      <div class="corner">{{corner}}</div>
      <div style="display: flex; justify-content: flex-end;">
        <van-button type="warning" size="small" style="margin:0 10px; width: 80px" @click="JumpToDetail(1)">修改</van-button>
        <van-button type="info" size="small" style="margin:0 10px; width: 80px"  @click="JumpToDetail(0)">查看详情</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyCard',
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      required: true
    },
    tagType: {
      type: String,
      required: true
    },
    corner: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    }
  },
  methods:{
    JumpToDetail(flag){
      this.$router.push({path: '/workDetail', query: {title: this.title, subTitle: this.subTitle, flag, corner: this.corner, content: this.content}})
    }
  }
}
</script>
<style scoped>
.card-container{
  padding: 16px;
  background: white;
  font-size: 30px;
}
.title{
  font-weight: bold;
  margin-left: 10px;
}
.tag{
  margin-left: 10px;
}
.sub-title{
  font-size: 26px;
  color: #565656;
  margin-left: 20px;
}
.content{
  font-size: 26px;
  color: #565656;
  margin-left: 10px;
  margin-top: 10px;
}
.corner{
  font-size: 24px;
  color: #999;
}
.van-image{
  width: 60px;
}
</style>
